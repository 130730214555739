(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:LicenseTeamDetails
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('LicenseTeamDetails', LicenseTeamDetails);

  function LicenseTeamDetails($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/teams/:teamId/licences/details', {teamId: '@_teamId'});
  }
}());
